<template>
    <div>
        <el-card>
            <template #header>
                <div class="clearfix" style="text-align:left">
                    <span>退款申请</span>
                </div>
            </template>
            <div class="content">
                <div class="content-header">
                    <el-row :gutter="5">
                        <el-col :span="24"   style="text-align:left;">
                            <div class="searchblock">
                                <el-select v-model="status" style="width:150px;margin-right:10px;">
                                    <el-option value="0" label="全部"></el-option>
                                    <el-option value="1" label="未打款"></el-option>
                                    <el-option value="2" label="已打款"></el-option>
                                </el-select>
                                <el-input v-model="keyword" placeholder="请输入订单号" style="width:250px;margin-right:10px;"></el-input>
                                <el-date-picker v-model="range" type="daterange" unlink-panels range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"  style="margin-right:10px;"></el-date-picker>
                                
                                <el-button type="primary" @click="GetRefundList" style="margin-left:10px;" class="hidden-sm-and-down">搜索</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="content-main">
                <el-table :data="tabledata" border style="width: 100%; margin: 1em 0"
                                v-loading="load" 
                                element-loading-spinner="el-icon-loading" 
                                element-loading-text="加载中..."
                                size="small"
                                >
                    <el-table-column label="订单号" prop="OrderUID"></el-table-column>
                    <el-table-column label="用户名" prop="Receiver" width="150px;"></el-table-column>
                    <el-table-column label="退款金额" prop="RefundAmount" width="100px;"></el-table-column>
                    <el-table-column label="退款备注" prop="Remark"></el-table-column>
                    
                    <el-table-column label="退款状态" prop="Status" width="100px;">
                        <template #default="scope">
                            <span v-if="scope.row.Status == 0">未打款</span>
                            <span v-if="scope.row.Status == 1">已打款</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="退款交易流水号" prop="TradeNo"></el-table-column>
                    <el-table-column label="确认退款时间">
                        <template #default="scope">
                            <span v-if="scope.row.PayDTime">{{formatDate(scope.row.PayDTime)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template #default="scope">
                            <el-button @click="Refund(scope.row.ID)" type="text" v-if="scope.row.Status == 0">确认退款</el-button>
                            <el-button @click="GetTrade(scope.row.OrderID)" type="text">交易流水</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentChange"
                                :current-page="curpage"
                                :page-size="pagesize"
                                layout="total, prev, pager, next, jumper"
                                :total="totalcount"
                                :hide-on-single-page="true"
                                style="text-align:center"></el-pagination>
            </div>
        </el-card>
    </div>
    <el-dialog v-model="refundinfo" title="填写退款交易流水号" center width="30%">
        <div style="margin-bottom:10px;">交易流水号：</div>
        <el-input v-model="refundno"></el-input>
        <div style="margin-bottom:10px;margin-top:10px;">备注：</div>
        <el-input type="textarea" v-model="remark"></el-input>
        <template #footer>
            <el-button type="primary" @click="Save">确定</el-button>
            <el-button @click="Close">取消</el-button>
        </template>
    </el-dialog>

    <el-dialog v-model="tradebill" title="交易流水" width="70%" center>
            <el-table :data="tradedata" border style="width: 100%; margin: 1em 0" size="medium">
                <el-table-column label="订单号" prop="OrderUID"></el-table-column>
                <el-table-column label="客户名" width="100px" prop="NickName"></el-table-column>
                <el-table-column label="交易流水号" prop="BillNo"></el-table-column>
                <el-table-column label="交易时间" prop="AddDTime">
                    <template #default='scope'>
                        <span>{{formatDate(scope.row.AddDTime)}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="交易金额" width="100px">
                    <template #default="scope">
                        <span v-if="scope.row.Source == 1" style="color:rgb(112, 182, 3)">+ {{scope.row.BillMoney}}</span>
                        <span v-if="scope.row.Source == 2" style="color:rgb(255, 20, 20)">- {{scope.row.BillMoney}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="交易类型" width="120px">
                    <template #default="scope">
                        <span v-if="scope.row.TradeType == 1">租金</span>
                        <span v-if="scope.row.TradeType == 2">押金</span>
                        <span v-if="scope.row.TradeType == 3">违约金</span>
                        <span v-if="scope.row.TradeType == 4">买断金</span>
                        <span v-if="scope.row.TradeType == 5">定损赔偿金</span>
                        <span v-if="scope.row.TradeType == 8">强制买断金</span>
                        <span v-if="scope.row.TradeType == 6">退款</span>
                        <span v-if="scope.row.TradeType == 7">补押金</span>

                    </template>
                </el-table-column>
                <el-table-column label="备注" prop="Remark"></el-table-column>
            </el-table>
        </el-dialog>
</template>
<script>
import constant from "@/constant"
export default {
    data(){
        return {
            curpage:1,
            pagesize:20,
            totalcount:0,
            keyword:'',
            status:'0',
            range:[],
            refundno:'',
            remark:'',
            refundid:'',
            tradebill:false,
            tradedata:[],
            refundinfo:false,
            tabledata:[]
        }
    },
    methods:{
        formatDate(date){
                var datetime = new Date(date*1000);
                var year = datetime.getFullYear();
                var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
                var day = ("0" + datetime.getDate()).slice(-2);
                var hour = ("0" + datetime.getHours()).slice(-2);
                var minute = ("0" + datetime.getMinutes()).slice(-2); 
                var second = ("0" + datetime.getSeconds()).slice(-2); 
                return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
        },
        Refund(id){
            console.log(id);
            this.refundid = id;
            this.refundinfo = true;
        },
        Save(){
            if(!this.refundno){
                this.$message.error("请填写退款流水交易号");
                return false;
            }else{
                this.axios.get(constant.updaterefund,{
                    headers:{
                        "Content-Type": "application/json",
                    },
                    params:{
                        refundno:this.refundno,
                        id:this.refundid,
                        remark:this.remark,
                    }
                }).then((response)=>{
                    console.log(response.data);
                    if(response.data == "OK"){
                        this.$message.success("操作成功");
                        this.$router.go(0);
                    }
                });
            }
        },
        Close(){
            this.refundno = '';
            this.remark = '';
            this.refundid = '';
            this.refundinfo = false;
        },
        GetRefundList(){
            this.axios.get(constant.refundlist,{
                headers:{
                    "Content-Type": "application/json",
                },
                params:{
                    curpage:this.curpage,
                    pagesize:this.pagesize,
                    range:JSON.stringify(this.range),
                    keyword:this.keyword,
                    status:this.status,
                }
            }).then((response)=>{
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage*1;
                this.totalcount = response.data.totalcount*1;
            });
        },
        handleCurrentChange(val){
            console.log(val);
            this.curpage = val;
            this.GetRefundList();
        },
        GetTrade(orderid){
            console.log(orderid);
            this.axios.get(constant.get_trade,{
                headers:{
                    "Content-Type": "application/json",
                },
                params:{
                    orderid:orderid
                }
            }).then((response)=>{
                console.log(response.data);
                this.tradedata = response.data.list;
                this.tradebill = true;
            });
        },
    },
    created:function(){
        this.GetRefundList();
    }
}
</script>
<style scoped>

</style>